<template>
  <el-dialog
    class="checkIn-dialog"
    title="新增入住"
    :visible.sync="show"
    :close-on-click-modal="false"
    width="720px"
    :before-close="cancel">
    <el-form
      ref="form"
      :model="formData"
      :rules="rules"
      label-width="100px"
    >
      <el-divider content-position="left">绑定信息</el-divider>
      <el-row>
        <el-col :span="18">
          <el-form-item label="搜索学生" prop="userName">
            <tool-tips-group is-flex tips-content="名字搜索最少输入2个汉字，身份证/录取编号搜索最少输入6位数字。<br/>只能搜索所管辖学院的学生。">
              <el-autocomplete
                v-model="userInfo.value"
                :fetch-suggestions="querySearchAsync"
                placeholder="学生名、身份证号、录取编号"
                @select="handleSelect" />
            </tool-tips-group>
          </el-form-item>
        </el-col>
      </el-row>
      <el-descriptions :column="2" border>
        <el-descriptions-item label-class-name="descriptions-item__label" label="姓名">
          {{ userInfo.stuName }}
        </el-descriptions-item>
        <el-descriptions-item label-class-name="descriptions-item__label" label="性别">
          {{ comptSexType(userInfo.sexType) }}
        </el-descriptions-item>
        <el-descriptions-item label-class-name="descriptions-item__label" label="年级">
          {{ userInfo.grade }}
        </el-descriptions-item>
        <el-descriptions-item label-class-name="descriptions-item__label" label="院系">
          {{ userInfo.collegeName }}
        </el-descriptions-item>
        <el-descriptions-item label-class-name="descriptions-item__label" label="专业">
          {{ userInfo.majorName }}
        </el-descriptions-item>
        <el-descriptions-item label-class-name="descriptions-item__label" label="班级">
          {{ userInfo.className || '--' }}
        </el-descriptions-item>
      </el-descriptions>
      <el-divider content-position="left">
        <tool-tips-group tips-content="请先选择学生，再进行分配宿舍。">
          选择宿舍
        </tool-tips-group>
      </el-divider>
      <el-row>
        <el-col :span="12">
          <el-form-item prop="dormitoryId" label="楼栋">
            <tool-tips-group is-flex tips-content="根据学生性别、专业等获取可分配宿舍楼。">
              <el-select
                v-model="userInfo.dormitoryId"
                clearable
                filterable
                @change="changeBuild"
                :disabled="!userInfo.userId||!dormBuildList.length"
              >
                <el-option
                  v-for="{dormitoryName, id} in dormBuildList"
                  :label="dormitoryName"
                  :value="id"
                  :key="id" />
              </el-select>
            </tool-tips-group>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="楼层" prop="floorNum">
            <el-select
              v-model="userInfo.floorNum"
              @change="changeFloorNum"
              :disabled="!userInfo.userId||!floorList"
              clearable
              filterable
            >
              <el-option
                v-for="num in floorList"
                :label="`第${num}层`"
                :value="num"
                :key="num" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="宿舍号" prop="dormitoryRoomId">
            <tool-tips-group is-flex
                             :tips-content="`获取${userInfo.floorNum?'第'+userInfo.floorNum+'层':'该楼层内'}已启用的宿舍（请到‘宿舍设置’模块先启用宿舍）。`">
              <el-select
                v-model="userInfo.dormitoryRoomId"
                @change="changeDormRoom"
                :disabled="!userInfo.userId||!roomList.length"
                clearable
                filterable
              >
                <el-option
                  v-for="{roomName , sexType , id} in roomList"
                  :label="`${roomName}-${comptSexType(sexType)}-${userInfo.majorName}`"
                  :value="id"
                  :key="id" />
              </el-select>
            </tool-tips-group>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="床位号" prop="id">
            <el-select
              v-model="formData.id"
              :disabled="!userInfo.userId||!roomBedList.length"
              clearable
              filterable
            >
              <el-option
                v-for="{dormitoryBedNo, stuName, userId, id} in roomBedList"
                :label="`${dormitoryBedNo}号床${userId?'(已入住:'+stuName+')':''}`"
                :value="id"
                :key="id" />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <template #footer>
      <cancel-popover ref="cancelRef" :disabled.sync="loadData.loading" :update-flag="updateFlag" />
      <el-button type="primary" @click="save('学生入住信息',addDormRoomBed)">确 定</el-button>
    </template>
  </el-dialog>
</template>

<script>
import { addDormRoomBed, listDormBuildByStuId, listDormRoomByDormitoryAndStuId } from '@/api/dorm'
import { copyData } from '@/utils/common-js'
import { getStudentByUserId } from '@/api/student'
import dialog from '@/vue/mixins/dialog'

export default {
  mixins: [dialog],
  props: {
    isSecondCollege: {
      type: Boolean,
      default: false
    },
    buildingList: {
      type: Array,
      default: () => ([])
    }
  },
  data () {
    return {
      formData: {
        id: null, // 床位id
        userId: '' // 绑定的学生id
      },
      queryInfo: {
        keyword: null
      },
      dormBuildList: [], // 宿舍楼
      floorList: 0, // 楼层
      roomList: [], // 宿舍列表
      roomBedList: [], // 床位
      studentList: [],
      userInfo: {
        value: '', // 绑定的学生信息（录取号+名字+身份证号）
        userId: '', // 学生id(来自绑定的学生)
        userName: '', // 绑定的学生名字
        examineNo: '', // 录取编号
        grade: null, // 年级(来自绑定的学生)
        collegeIde: '', // 学院id(来自绑定的学生)
        collegeName: '', // 学院名(来自绑定的学生)
        majorId: '', // 专业id(来自绑定的学生)
        majorName: '', // 专业名(来自绑定的学生)
        className: '', // 班级名(来自绑定的学生)
        stuName: '', // 学生名(来自绑定的学生)
        sexType: null, // 性别名(来自绑定的学生)
        stuStatus: null, // 学籍状态(来自绑定的学生)
        // 宿舍信息
        dormitoryId: '', // 楼栋
        floorNum: null, // 楼层
        startUpFlag: 1, // 是否启用 1-默认启用
        dormitoryRoomId: '', // 宿舍号
        dormitoryBedId: '' // 床位号
      },
      rules: {
        userId: [{
          required: true,
          message: '请选择学生',
          trigger: 'blur'
        }],
        id: {
          required: true,
          message: '请选择床位号',
          trigger: 'blur'
        }
      }
    }
  },
  computed: {
    comptSexType () {
      return (sexType) => {
        return Number(sexType) === 1 ? '男' : Number(sexType) === 2 ? '女' : ''
      }
    }
  },
  methods: {
    addDormRoomBed, // 保存api
    // 获取楼栋
    getDormBuild (query) {
      this.floorList = 0
      this.clearList(this.roomList)
      this.clearList(this.roomBedList)
      listDormBuildByStuId(query).then((res) => {
        this.dormBuildList = res.data
      })
    },
    // 选择楼栋
    changeBuild (id) {
      this.userInfo.floorNum = ''
      this.userInfo.dormitoryRoomId = ''
      this.userInfo.dormitoryBedId = ''
      this.floorList = 0
      this.clearList(this.roomList)
      this.clearList(this.roomBedList)
      this.createFloor(id)
    },
    // 生成楼层
    createFloor (id) {
      this.floorList = this.dormBuildList.filter((item) => item.id === id)[0]
        .floorAmount
    },
    // 选择楼层
    async changeFloorNum () {
      this.userInfo.dormitoryRoomId = ''
      this.userInfo.dormitoryBedId = ''
      this.clearList(this.roomList)
      this.clearList(this.roomBedList)
      await this.getDormRoomList()
    },
    /* 选择宿舍 床位List
    * dormitoryRoomId {String} 宿舍id
     */
    changeDormRoom (val) {
      this.userInfo.dormitoryBedId = ''
      this.clearList(this.roomBedList)
      if (val) {
        // 根据宿舍号获取床位
        this.$http.getDormRoomBedListQuery({ dormitoryRoomId: val })
          .then((res) => {
            this.roomBedList = res.data
          })
      }
    },
    /* 获取宿舍列表
    * dormitoryId {String} 宿舍楼id
    * floorNum {Number} 楼层
    * majorId {String} 学生的专业
    * userId {String} 学生userId
     */
    async getDormRoomList () {
      this.userInfo.dormitoryBedId = ''
      this.clearList(this.roomBedList)
      try {
        const _query = {
          dormitoryId: this.userInfo.dormitoryId,
          floorNum: this.userInfo.floorNum,
          majorId: this.userInfo.majorId,
          userId: this.userInfo.userId
        }
        const res = await listDormRoomByDormitoryAndStuId(_query)
        if (res.data.length) {
          this.roomList = res.data.filter(({ sexType }) => Number(sexType) === Number(this.userInfo.sexType))
        }
      } catch (e) {

      }
    },
    // 根据宿舍号获取床位
    getRoomBedList (dormitoryRoomId) {
      this.$http
        .getDormRoomBedListQuery({
          dormitoryRoomId: dormitoryRoomId
        })
        .then((res) => {
          this.roomBedList = res.data
        })
    },
    // 远程搜索 学生名
    querySearchAsync (keyword, cb) {
      let _result = []
      /* 判断是否请求接口 为false 时请求接口
      * 默认为 true ,因为为false+keyword===''时加载所有的几万条学生数据
      * */
      let _correctQuery = false
      if (keyword) {
        if (!isNaN(keyword)) {
          // 支持学号、身份证号(number)搜索,但是学号/考号/身份证号必须填入6位数以上,减轻服务器压力
          if (keyword.length > 5) {
            _correctQuery = true
          }
        } else if (keyword.length > 1) {
          // 为string,且关键字大于一个字才能开始搜索,减轻服务器压力
          _correctQuery = true
        }
      }
      if (_correctQuery) {
        this.$http.getStudentListQuery({
          keyword: keyword,
          collegeId: this.$store.state.login.userInfo.collegeId || null
        }).then((res) => {
          res.data.map((stu) => {
            stu.value = `${stu.examineNo}-${stu.stuName}(身份证:${stu.idNumber})`
          })
          _result = res.data
          if (_result.length) this.formData.userId = null
          cb(_result)
        })
      } else {
        // 先清空，再开始搜索
        this.formData.userId = null
        Object.keys(this.userInfo).forEach(key => {
          // value= 搜索框关键字，不可清 startUpFlag 启用
          if (key === 'startUpFlag' || key === 'value') {
          } else {
            this.userInfo[key] = null
          }
        })
        this.floorList = 0
        this.clearList(this.dormBuildList)
        this.clearList(this.roomList)
        this.clearList(this.roomBedList)
        cb(_result)
      }
    },
    // 获取选中的学生信息
    handleSelect (stu) {
      if (stu) {
        if (stu.dormitoryBedNo) {
          this.$message.error(`学生${stu.stuName}已分配宿舍（${stu.dormitoryName}_${stu.floorNum}层_${stu.roomName}_${stu.dormitoryBedNo}号床），请清空该生宿舍后再进行重新分配！`)
        } else {
          this.formData.userId = stu.userId
          copyData(stu, this.userInfo)
          if (this.isSecondCollege) {
            this.dormBuildList = this.buildingList.filter((item) => Number(item.sexType) === Number(this.userInfo.sexType))
          } else {
            this.getDormBuild({ userId: stu.userId })
          }
        }
      }
    },
    // 根据userId获取绑定得学生信息
    async getStuDataByUserId (id) {
      try {
        const res = await getStudentByUserId(id)
        copyData(res.data, this.userInfo)
      } catch (e) {
      }
    }
  }
}
</script>
<style lang="scss">
.checkIn-dialog {
  .el-dialog {
    .el-dialog__body {
      padding-top: 0;

      .descriptions-item__label {
        width: 80px;
      }
    }
  }
}
</style>
