<!--学生宿舍-->
<template>
  <div style="width: 100%;">
    <el-table :data="dormBedData" style="width: 100%" border stripe size="mini">
      <el-table-column prop="dormitoryBedNo" label="床位号" width="80">
        <template v-slot="{row}">
          <span>{{ row.dormitoryBedNo }}</span>
        </template>
      </el-table-column>
      <el-table-column label="状态" width="80">
        <template v-slot="{ row }">
          <span v-if="!row.userId">空床位</span>
          <span v-else>已绑定</span>
        </template>
      </el-table-column>
      <el-table-column label="启动状态" width="80">
        <template v-slot="{ row }">
          <el-tag type="success" v-if="Number(row.startUpFlag) === 1">启用</el-tag>
          <el-tag type="danger" v-else>禁用</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="stuName" label="姓名" width="100" />
      <el-table-column prop="sexType" label="性别" width="50">
        <template v-slot="{ row }">
          {{ Number(row.sexType) === 1 ? '男' : (Number(row.sexType) === 2 ? '女' : '') }}
        </template>
      </el-table-column>
      <el-table-column prop="fixedPhone" label="联系电话" width="110" />
      <!--  roomInfo.inRule=4 为教职工，需要隐藏不必要的字段  -->
      <el-table-column prop="job" label="职务" width="100" />
      <el-table-column label="学籍状态" width="100">
        <template v-slot="{ row }">
          {{ $http.getParamName(row.stuStatus, $store.state.systemParam.stuStatus) }}
        </template>
      </el-table-column>
      <el-table-column prop="className" label="班别" min-width="160" />
      <el-table-column prop="collegeName" label="系别" min-width="120" />
      <el-table-column prop="majorName" label="专业" width="120" />
      <el-table-column prop="managerUserName" label="辅导员" />
      <el-table-column prop="broadbandAccount" width="120" label="宽带账号" />
      <el-table-column width="200" fixed="right" label="操作">
        <template v-slot="{ row }">
            <!--    学生宿舍   -->
            <el-button v-permission="['dormitoryBed:bind','secondCollege:bed:bind']" type="primary"
                       size="mini"
                       @click="showBindDialog(row)">
              绑定学生
            </el-button>
            <el-button v-permission="['dormitoryBed:edit','secondCollege:bed:edit']" type="primary" size="mini"
                       @click="showDialog(row.id)">编辑
            </el-button>
            <el-popover
              placement="left"
              width="120"
              title="更多操作"
              trigger="hover">
              <el-button type="danger" size="mini" plain
                         v-permission="['dormitoryBed:clear','secondCollege:stu:clear']"
                         @click="$emit('on-clear',row)">清空
              </el-button>
              <el-button type="danger" size="mini" v-permission="['dormitoryBed:del','secondCollege:stu:del']"
                         @click="del(deleteDormRoomBed,row.id, `${currentRoomInfo.floorNum }楼${currentRoomInfo.label}房${row.dormitoryBedNo}号床位`)">
                删除
              </el-button>
              <!--  无法获取学生id 无法绑定门禁  -->
              <!--    <el-button type="primary" size="mini" plain-->
              <!--               v-permission="['dormitoryBed:edit','secondCollege:bed:edit']"-->
              <!--               @click="showSubDialog('visibleCheckInPhotoDialog','checkInPhotoRef','',row)">门禁照片-->
              <!--    </el-button>-->
              <el-button slot="reference"
                         v-permission="['dormitoryBed:clear','secondCollege:stu:clear','dormitoryBed:del','secondCollege:stu:del','dormitoryBed:edit','secondCollege:bed:edit']"
                         icon="el-icon-more" />
            </el-popover>
        </template>
      </el-table-column>
    </el-table>
    <!--  添加/编辑床位 弹窗  -->
    <addRoomBed ref="dialogRef" :dorm-info="currentRoomInfo" :visible.sync="visibleDialog" v-if="visibleDialog"
                @on-close="$emit('on-reload')" />
    <!-- 绑定学生宿舍床位 -->
    <bind-student ref="bindDialogRef" :visible.sync="visibleBindDialog" v-if="visibleBindDialog"
                  @on-close="$emit('on-reload')" />
    <!--  门禁照片录入 dialog  -->
    <check-in-photo-dialog ref="checkInPhotoRef" :visible.sync="visibleCheckInPhotoDialog"
                           v-if="visibleCheckInPhotoDialog"
                           @on-close="renderTable" />
  </div>
</template>

<script>
import tableView from '@/vue/mixins/table-view'
import { deleteDormRoomBed } from '@/api/dorm'
import { copyData } from '@/utils/common-js'
import BindStudent from '@/views/pages/dormManage/dialog/bindStudent.vue'
import addRoomBed from '@/views/pages/dormManage/dialog/addDormRoomBed.vue'
import CheckInPhotoDialog from '@/views/pages/dormManage/dorm-room-bed/component/CheckInPhotoDialog.vue'

export default {
  name: 'dormStuView',
  components: {
    CheckInPhotoDialog,
    BindStudent,
    addRoomBed
  },
  mixins: [tableView],
  props: {
    dormBedData: {
      type: Array,
      default: () => ([])
    },
    currentRoomInfo: { // 当前显示的宿舍信息
      type: Object,
      default: () => ({
        floorNum: '',
        label: ''
      })
    }
  },
  data() {
    return {
      visibleBindDialog: false, // 学生宿舍信息
      visibleCheckInPhotoDialog: false // 门禁照片 dialog 无法获取学生id
    }
  },
  methods: {
    deleteDormRoomBed, // 删除api
    // 绑定学生 弹窗
    showBindDialog(row) {
      this.visibleBindDialog = true
      this.$nextTick(() => {
        copyData(row, this.$refs.bindDialogRef.formData)
        // 获取当前绑定的学生信息
        copyData(this.currentRoomInfo, this.$refs.bindDialogRef.dormInfo)
        this.$refs.bindDialogRef.setInitData()
      })
    },
    renderTable() {
      this.$emit('on-reload')
    }
  }
}
</script>
