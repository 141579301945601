<template>
  <table-view v-loading="loading" :show-page="false">
    <template #header>
      <div style="height:auto;overflow:hidden;">
        <tool-tips-group class="fl"
                         tips-content="名字搜索最少输入2个汉字。<br/>
                         身份证/录取编号搜索最少输入6位数字。<br/>
                         <b class='font-danger'>教职工搜索只显示在住的教职工。</b><br/>"
                         style="margin-top: 6px;margin-bottom: var(--space-primary);">
          <!--   <br/>只能搜索所管辖学院的学生。  <span>搜索学生：</span>-->

          <el-radio-group class="mr-1" v-model="formData.userType" size="small">
            <el-radio-button :label="2">搜索学生</el-radio-button>
            <el-radio-button :label="3">搜索教师</el-radio-button>
          </el-radio-group>
          <el-autocomplete
            v-model="userInfo.value"
            :fetch-suggestions="querySearchAsync"
            :placeholder="formData.userType===2?'学生名、身份证号、录取编号':'教师姓名、身份证、工号'"
            size="small"
            clearable
            @select="handleSelect" style="display: inline-block;width:288px;" />
        </tool-tips-group>
        <div class="header-button fr" v-permission="['dormitoryBed:edit','secondCollege:bed:edit']">
          <upload-excel :permit-key="['dormitoryBed:edit','secondCollege:bed:edit']"
                        :loading.sync="loading"
                        :api="$http.importExcelHttp.importDormRoomBed"
                        download-title="导入宽带账号模板"
                        import-title="批量导入宽带账号"
                        @on-download="$http.exportExcelHttp.exportDormRoomBedTemplate()"
                        @on-success="renderTable" />
          <el-button type="primary" size="small" @click="showSubDialog('visibleCheckInDialog','checkInDialogRef')"
          >新增入住
          </el-button>
          <tool-tips-group is-flex
                           tips-content="此工具栏仅支持搜索、批量绑定、单独绑定学生。<br/><b>暂不支持批量导入、搜索教职工。</b>"
                           style="margin-top: 3px;" />
        </div>
      </div>
    </template>

    <!-- body -->
    <el-card>
      <el-row>
        <el-col :span="3">
          <el-scrollbar style="height: 100%">
            <div style="max-height: 70vh">
              <el-tree
                style="height: 100%"
                :data="dormTree"
                accordion
                node-key="id"
                :default-expanded-keys="[currentRoomId]"
                :props="defaultProps"
                @node-click="handleNodeClick" />
            </div>
          </el-scrollbar>
        </el-col>
        <el-col :span="21" v-if="currentRoomId">
          <div class="flex mb-1" style="align-items: center;">
            <div class="flex-1">
              <b>{{
                  currentRoomInfo.buildName
                }}{{ currentRoomInfo.floorNum }}楼{{
                  currentRoomInfo.label
                }}</b>
              <span>
                  <span v-if="Number(roomInfo.inRule) === 3"
                  >(混合宿舍)，</span>
                  <span v-else>
                    <span v-if="roomInfo.collegeName"
                    >，{{ roomInfo.collegeName }}</span>
                    <span v-if="roomInfo.majorName"
                    >，{{ roomInfo.majorName }}</span>
                    <span v-if="roomInfo.className"
                    >，{{ roomInfo.className }}</span>，
                  </span>
                </span>
              <span
                v-if="roomInfo.inRule===4">教师宿舍，</span>
              <span
                v-if="roomInfo.inRule!==4">{{ Number(roomInfo.sexType) === 1 ? '男生宿舍' : (Number(roomInfo.sexType) === 2 ? '女生宿舍' : '')
                }} ，</span>
              <b>{{ currentRoomTypeInfo.roomTypeName }} </b>
              <span>(已入住 <b>{{ currentRoomInfo.noEmptyBedNum }}</b> 人) ，</span>
              <span>空床位 <b style="color: var(--color-danger)">{{ currentRoomInfo.emptyBedNum }}</b>个</span>
            </div>
            <span v-if="!isSecondCollege">
                <el-button v-permission="['dormitoryBed:clear','secondCollege:stu:clear']" type="danger" plain
                           size="small"
                           @click="clearRoomBedList">一键清空</el-button>
              </span>
            <el-button v-if="hasPermiKeys(['dormitoryBed:edit','secondCollege:bed:edit'])" type="primary"
                       style="margin-left: 10px" size="small" @click="showDialog()">
              新增床位
            </el-button>
          </div>
          <!--  教职工宿舍  -->
          <!--  请注意： 宿舍管理员没有管理‘人’的权限   -->
          <dorm-teacher-view v-if="roomInfo.inRule===4" :dorm-bed-data="bedList"
                             :current-room-info="currentRoomInfo"
                             @on-reload="renderTable"
                             @on-clear="clearBed" />
          <!--  学生宿舍  -->
          <dorm-stu-view v-else :dorm-bed-data="bedList" :current-room-info="currentRoomInfo"
                         @on-reload="renderTable"
                         @on-clear="clearBed" />
        </el-col>
      </el-row>
    </el-card>
    <!--  添加/编辑床位 弹窗  -->
    <addRoomBed ref="dialogRef" :dorm-info="currentRoomInfo" :visible.sync="visibleDialog" v-if="visibleDialog"
                @on-close="renderTable" />
    <!--  新增入住， 任意学生绑定任意床位 dialog  -->
    <checkIn ref="checkInDialogRef" :is-second-college="isSecondCollege" :building-list="buildingList"
             :visible.sync="visibleCheckInDialog" v-if="visibleCheckInDialog"
             @on-close="renderTable" />
    <!--  学生信息 dialog  -->
    <stu-info-dialog ref="stuInfoRef" :visible.sync="visibleStuInfoDialog" v-if="visibleStuInfoDialog"
                     @on-close="()=>userInfo.value=keyword=''" />
    <teacher-info-dialog ref="teacherInfoRef" :visible.sync="visibleTeacherInfoDialog"
                         v-if="visibleTeacherInfoDialog" />
  </table-view>
</template>

<script>
import {
  addDormRoomBed,
  getDormRoomById,
  getDormRoomTypeById
} from '@/api/dorm'
import addRoomBed from '../dialog/addDormRoomBed.vue'
import checkIn from '../dialog/checkIn.vue'
import tableView from '@/vue/mixins/table-view'
import StuInfoDialog from '@/views/pages/dormManage/dialog/stuInfoDialog.vue'
import DormTeacherView from '@/views/pages/dormManage/dorm-room-bed/component/dormTeacherView.vue'
import DormStuView from '@/views/pages/dormManage/dorm-room-bed/component/dormStuView.vue'
import userAutocomplete from '@/vue/mixins/user-autocomplete'
import TeacherInfoDialog from '@/views/pages/dormManage/dialog/teacherInfoDialog.vue'
import { intersection } from 'lodash'

export default {
  name: 'dormRoomBed',
  components: {
    TeacherInfoDialog,
    DormStuView,
    DormTeacherView,
    StuInfoDialog,
    addRoomBed,
    checkIn
  },
  mixins: [tableView, userAutocomplete],
  data() {
    return {
      visibleCheckInPhotoDialog: false,
      // 用于远程搜索判断使用的接口
      formData: {
        userType: 2
      },
      keyword: '', // 学生、教师名字搜索
      userInfo: {
        value: ''
      },
      queryInfo: {
        dormitoryRoomId: ''
      },
      bedList: [],
      buildingList: [], // 楼栋
      dormTree: [],
      currentRoomId: '', // 当前房间id
      currentRoomInfo: {}, // 当前房间数据
      currentRoomTypeInfo: {}, // 当前房间类型数据
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      roomInfo: {},
      isSecondCollege: false,
      visibleCheckInDialog: false,
      visibleStuInfoDialog: false, // 查看学生信息 弹窗
      visibleTeacherInfoDialog: false // 查看教师信息 弹窗
    }
  },
  watch: {
    $route: {
      handler(to) {
        if (to.path === '/secondCollege/studentCheckIn') {
          this.isSecondCollege = true
          this.getDormTree({
            collegeId: this.$store.state.login.userInfo.collegeId
          })
        } else {
          this.isSecondCollege = false
          this.getDormTree({})
        }
      },
      deep: true,
      immediate: true
    }
  },
  created() {
    this.isDorm = true
  },
  computed: {
    // 判断是否楼栋管理员or超级管理员
    isEditor() {
      let _editor = false
      // dormitoryIdList 里存在本宿舍楼id时，代表为本楼栋管理员，可以编辑
      if (this.currentFloor?.dormitoryInfoId && this.$store.state.login.userInfo?.dormitoryIdList?.length) {
        _editor = this.$store.state.login.userInfo.dormitoryIdList.includes(this.currentFloor.dormitoryInfoId)
      }
      return _editor
    }
  },
  methods: {
    // 拥有编辑权限的可以操作数据
    hasPermiKeys(arr = []) {
      return this.isEditor || intersection(this.$store.state.login.userInfo.paraNameList, arr)?.length
    },
    // 一键清空宿舍床位
    clearRoomBedList() {
      // 过滤空数据
      const bedList = this.bedList.filter((item) => item.userId !== null)
      if (bedList.length > 0) {
        this.clearBed(bedList, 'all')
      } else {
        this.$message.warning('暂无入住')
      }
    },
    // 清空床位
    clearBed(row, flag = '') {
      const createArray = Array.isArray(row) ? row : [row]
      const list = createArray.filter((item) => item.userId !== null)
      if (list.length === 0) {
        this.$message.warning('暂无入住')
        return false
      }
      this.$confirm(`清空${flag === 'all' ? '房间内所有' : row.dormitoryBedNo + '号'}床位, 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          for (let i = 0; i < list.length; i++) {
            addDormRoomBed({
              userId: null,
              id: list[i].id,
              dormitoryRoomId: list[i].dormitoryRoomId,
              dormitoryBedNo: list[i].dormitoryBedNo
            }).then(() => {
              if (i + 1 === list.length) {
                this.$message.success('已清空')
                this.renderTable()
              }
            })
          }
        })
        .catch(() => {
          this.$message('已取消')
        })
    },
    // 根据id获取房间信息
    getDormRoomById(dormitoryRoomId) {
      getDormRoomById(dormitoryRoomId).then((res) => {
        this.roomInfo = res.data
        this.getDormRoomTypeById(this.roomInfo.roomTypeId)
      })
    },
    // 根据id获取房间房间类型
    getDormRoomTypeById(dormitoryRoomId) {
      getDormRoomTypeById(dormitoryRoomId).then((res) => {
        this.currentRoomTypeInfo = res.data
      })
    },
    // 当前楼栋
    getCurrentBuild(dormitoryInfoId) {
      this.dormTree.forEach((item) => {
        if (item.id === dormitoryInfoId) {
          this.currentRoomInfo.buildName = item.label
        }
      })
    },
    // 点击树节点
    handleNodeClick(data, node) {
      if (node.level === 3) {
        ({
          id: this.currentRoomId,
          dormitoryRoomId: this.queryInfo.dormitoryRoomId
        } = data)
        this.bedList = []
        this.currentRoomInfo = data
        this.getDormRoomById(data.dormitoryRoomId)
        this.getCurrentBuild(data.dormitoryInfoId)
        this.renderTable()
      }
    },
    // 获取楼层树
    getDormTree(query) {
      this.$http.getDormTree(query).then((res) => {
        this.dormTree = res.data
        this.buildingList = res.data.map((item) => {
          return {
            id: item.id,
            dormitoryName: item.label,
            floorAmount: item.floorAmount,
            sexType: item.sexType
          }
        })
        if (this.dormTree[0].children[0].children.length > 0) {
          this.queryInfo.dormitoryRoomId =
            this.dormTree[0].children[0].children[0].id
          this.currentRoomId = this.dormTree[0].children[0].children[0].id
          this.currentRoomInfo = this.dormTree[0].children[0].children[0]
          this.getCurrentBuild(
            this.dormTree[0].children[0].children[0].dormitoryInfoId
          )
          this.getDormRoomById(this.currentRoomId)
        }
        this.renderTable()
      })
    },
    // 获取床位列表
    renderTable() {
      this.$http.getDormRoomBedListQuery(this.queryInfo).then((res) => {
        this.bedList = res.data.sort(
          (a, b) => Number(a.dormitoryBedNo) - Number(b.dormitoryBedNo)
        )
      })
    },
    // 获取选中的学生信息
    handleSelect(stu) {
      if (stu) {
        if (stu.dormitoryBedNo) {
          if (stu.stuName) {
            // title 字段用于传学生数据
            this.showSubDialog('visibleStuInfoDialog', 'stuInfoRef', '', stu)
          } else {
            // title 字段用于传教师数据
            this.showSubDialog('visibleTeacherInfoDialog', 'teacherInfoRef', '', stu)
          }
        } else {
          this.$message(`${stu.stuName || stu.realName}暂未分配宿舍！`)
        }
      }
    }
  }
}
</script>
