<template>
  <el-dialog
    class="bind-dorm-room-bed-dialog"
    title="绑定床位"
    :visible.sync="show"
    :close-on-click-modal="false"
    width="720px"
    :before-close="closed"
  >
    <el-form
      ref="form"
      :model="formData"
      :rules="rules"
      label-width="100px"
    >
      <el-row>
        <el-col :span="24">
          <el-form-item>
            <template #label>
              <tool-tips-group is-flex
                               tips-content="修改绑定学生前，需要先点击【清空绑定学生】按钮清空床位。<br/>只能搜索跟<b>宿舍设定性别相同的学生</b>。<br/>名字搜索最少输入两个字，身份证/录取编号搜索最少输入6位数字。<br/>只能搜索管辖院系内的学生。">
                搜索学生
              </tool-tips-group>
            </template>
            <template v-if="clearDisabled">
              <el-autocomplete
                v-model="userInfo.value"
                :fetch-suggestions="querySearchAsync"
                placeholder="请输入学生名、身份证号、录取编号"
                @select="handleSelect" />
            </template>
            <span class="font-grey" v-else>已绑定学生</span>
          </el-form-item>
        </el-col>
      </el-row>
      <el-divider content-position="left">绑定信息</el-divider>
      <el-descriptions :column="2" border>
        <el-descriptions-item label-class-name="descriptions-item__label" label="姓名">
          {{ userInfo.stuName }}
        </el-descriptions-item>
        <el-descriptions-item label-class-name="descriptions-item__label" label="性别">
          {{ comptSexType(userInfo.sexType) }}
        </el-descriptions-item>
        <el-descriptions-item label-class-name="descriptions-item__label" label="年级">
          {{ userInfo.grade }}
        </el-descriptions-item>
        <el-descriptions-item label-class-name="descriptions-item__label" label="院系">
          {{ userInfo.collegeName }}
        </el-descriptions-item>
        <el-descriptions-item label-class-name="descriptions-item__label" label="专业">
          {{ userInfo.majorName }}
        </el-descriptions-item>
        <el-descriptions-item label-class-name="descriptions-item__label" label="班级">
          {{ userInfo.className || '--' }}
        </el-descriptions-item>
        <el-descriptions-item label-class-name="descriptions-item__label" label="楼栋">
          {{ dormInfo.buildName }}
        </el-descriptions-item>
        <el-descriptions-item label-class-name="descriptions-item__label" label="楼层">
          第{{ dormInfo.floorNum }}层
        </el-descriptions-item>
        <el-descriptions-item label-class-name="descriptions-item__label" label="宿舍号">
          {{ dormInfo.label }}
        </el-descriptions-item>
        <el-descriptions-item label-class-name="descriptions-item__label" label="床位号">
          {{ formData.dormitoryBedNo }}
        </el-descriptions-item>
      </el-descriptions>
    </el-form>
    <template #footer>
      <el-button @click="closed">取 消</el-button>
      <el-button type="danger" plain :disabled="clearDisabled"
                 v-permission="['dormitoryBed:bind','secondCollege:bed:bind']"
                 @click="clearBed">清空绑定学生
      </el-button>
      <el-button type="primary" @click="save('绑定学生',addDormRoomBed,'绑定成功')">确 定</el-button>
    </template>
  </el-dialog>
</template>

<script>
import { addDormRoomBed } from '@/api/dorm'
import { getStudentByUserId } from '@/api/student'
import dialog from '@/vue/mixins/dialog'
import { copyData } from '@/utils/common-js'

export default {
  mixins: [dialog],
  data () {
    return {
      formData: {
        id: null,
        userId: '', // 学生id(来自绑定的学生)
        dormitoryBedNo: '', // 床位号
        dormitoryRoomId: '' // 宿舍号
      },
      clearDisabled: true,
      // 宿舍楼信息
      dormInfo: {
        floorNum: '',
        buildName: '',
        dormitoryInfoId: '',
        label: '',
        sexType: null
      },
      dormBuildList: [],
      studentList: [],
      userInfo: {
        userId: '', // 学生id(来自绑定的学生)
        userName: '',
        examineNo: '',
        value: '',
        grade: null,
        collegeName: '', // 学院名(来自绑定的学生)
        majorName: '', // 专业名(来自绑定的学生)
        className: '', // 班级名(来自绑定的学生)
        stuName: '', // 学生名(来自绑定的学生)
        sexType: null, // 性别名(来自绑定的学生)
        stuStatus: null // 学籍状态(来自绑定的学生)
      }
    }
  },
  computed: {
    comptSexType (val) {
      return (sexType) => {
        return Number(sexType) === 1 ? '男' : Number(sexType) === 2 ? '女' : ''
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      if (!this.formData.id) {
        this.$message.error('请求失败,请稍后重试!')
        this.close()
        return
      }
      if (this.formData.userId) {
        // 已绑定学生时,设置id(用于判断 清空按钮是否可点击)
        this.id = this.formData.id
        this.getStuDataByUserId(this.formData.userId)
        this.clearDisabled = false
      }
    })
  },
  methods: {
    addDormRoomBed, // 保存api
    // 远程搜索 学生名
    querySearchAsync (keyword, cb) {
      let _result = []
      /* 判断是否请求接口 为false 时请求接口
      * 默认为 true ,因为为false+keyword===''时加载所有的几万条学生数据
      * */
      let _correctQuery = false
      if (keyword) {
        if (!isNaN(keyword)) {
          // 支持学号、身份证号(number)搜索,但是学号/考号/身份证号必须填入6位数以上,减轻服务器压力
          if (keyword.length > 5) {
            _correctQuery = true
          }
        } else if (keyword.length > 1) {
          // 为string,且关键字大于一个字才能开始搜索,减轻服务器压力
          _correctQuery = true
        }
      }
      if (_correctQuery) {
        this.$http.getStudentListQuery({
          keyword: keyword,
          sexType: this.dormInfo.sexType,
          collegeId: this.$store.state.login.userInfo.collegeId || null
        }).then((res) => {
          res.data.map((stu) => {
            stu.value = `${stu.examineNo}-${stu.stuName}(身份证:${stu.idNumber})`
          })
          _result = res.data
          cb(_result)
        })
      } else {
        this.formData.userId = null
        Object.keys(this.userInfo).forEach(key => {
          if (key !== 'value') {
            this.userInfo[key] = null
          }
        })
        cb(_result)
      }
    },
    // 获取选中的学生信息
    handleSelect (stu) {
      this.formData.userId = stu.userId
      copyData(stu, this.userInfo)
    },
    // 根据userId获取绑定得学生信息
    async getStuDataByUserId (id) {
      try {
        const res = await getStudentByUserId(id)
        copyData(res.data, this.userInfo)
      } catch (e) {
      }
    },
    // 清空床位
    clearBed () {
      if (!this.formData.userId) {
        this.$message.error('未绑定学生,无法执行清空操作!')
        return
      }
      this.$confirm('清空该床位, 是否继续?', '提示')
        .then(async () => {
          try {
            this.formData.userId = ''
            await addDormRoomBed(this.formData)
            Object.keys(this.userInfo).forEach(key => {
              this.userInfo[key] = null
            })
            this.$message.success('已清空')
            this.clearDisabled = true
            this.setInitData()
          } catch (e) {
          }
        })
        .catch(() => {
          this.$message('已取消')
        })
    }
  }
}
</script>
<style lang="scss">
.bind-dorm-room-bed-dialog {
  .el-dialog {
    .el-dialog__body {
      padding-top: 0;

      .descriptions-item__label {
        width: 80px;
      }
    }
  }
}
</style>
