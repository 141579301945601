<template>
  <el-dialog
    class="bind-bed-by-teacher-dialog"
    title="绑定教职工宿舍床位"
    :visible.sync="show"
    :close-on-click-modal="false"
    width="720px"
    :before-close="closed"
  >
    <el-form ref="form" :model="formData" :rules="rules" label-width="130px">
      <el-row>
        <el-col :span="24">
          <el-form-item>
            <template #label>
              <tool-tips-group is-flex
                               tips-content="修改绑定教职工前，需要先点击【清空绑定教职工】按钮清空床位。">
                搜索教职工
              </tool-tips-group>
            </template>
            <template v-if="clearDisabled">
              <el-autocomplete
                v-model="userInfo.value"
                :fetch-suggestions="querySearchAsync"
                placeholder="请输入教职工名"
                @select="handleSelect" />
            </template>
            <span class="font-grey" v-else>已绑定教职工</span>
          </el-form-item>
        </el-col>
      </el-row>
      <el-divider content-position="left">绑定信息</el-divider>
      <el-descriptions :column="2" border>
        <el-descriptions-item label-class-name="descriptions-item__label" :span="2" label="姓名">
          {{ userInfo.realName }}
        </el-descriptions-item>
        <el-descriptions-item label-class-name="descriptions-item__label" label="身份证号">
          {{ userInfo.cardNum }}
        </el-descriptions-item>
        <el-descriptions-item label-class-name="descriptions-item__label" label="电话号码">
          {{ userInfo.phone }}
        </el-descriptions-item>
        <el-descriptions-item label-class-name="descriptions-item__label" label="院系">
          {{ userInfo.collegeName }}
        </el-descriptions-item>
        <el-descriptions-item label-class-name="descriptions-item__label" label="部门">
          {{ userInfo.deptName }}
        </el-descriptions-item>
        <el-descriptions-item label-class-name="descriptions-item__label" label="楼栋">
          {{ title.buildName }}
        </el-descriptions-item>
        <el-descriptions-item label-class-name="descriptions-item__label" label="楼层">
          第{{ title.floorNum }}层
        </el-descriptions-item>
        <el-descriptions-item label-class-name="descriptions-item__label" label="宿舍号">
          {{ title.label }}
        </el-descriptions-item>
        <el-descriptions-item label-class-name="descriptions-item__label" label="床位号">
          {{ formData.dormitoryBedNo }}
        </el-descriptions-item>
      </el-descriptions>
    </el-form>
    <template #footer>
      <el-button @click="closed">取 消</el-button>
      <el-button type="danger" plain :disabled="clearDisabled"
                 v-permission="['dormitoryBed:bind','secondCollege:bed:bind']"
                 @click="clearBed">清空绑定教职工
      </el-button>
      <el-button type="primary" @click="save('绑定教职工',addDormRoomBed,'绑定成功')">确 定</el-button>
    </template>
  </el-dialog>
</template>

<script>
import { addDormRoomBed } from '@/api/dorm'
import dialog from '@/vue/mixins/dialog'
import { copyData } from '@/utils/common-js'
import { getUserInfoById, getUserList } from '@/api/system/user-api'

export default {
  mixins: [dialog],
  data () {
    return {
      formData: {
        id: null,
        userId: '', // 教职工id(来自绑定的教职工)
        dormitoryBedNo: '', // 床位号
        dormitoryRoomId: '' // 宿舍号
      },
      clearDisabled: true,
      dormBuildList: [],
      studentList: [],
      userInfo: {
        id: '', // 教师id(来自绑定的教职工)
        realName: '',
        value: '',
        collegeName: '', // 学院名(来自绑定的教职工)
        deptName: '', // 部门名(来自绑定的教职工)
        phone: '', // 手机号(来自绑定的教职工)
        cardNum: '' // 身份证(来自绑定的教职工)
      }
    }
  },
  computed: {
    comptSexType (val) {
      return (sexType) => {
        return Number(sexType) === 1 ? '男' : Number(sexType) === 2 ? '女' : ''
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      if (!this.id) {
        this.$message.error('请求失败,请稍后重试!')
        this.close()
        return
      }
      copyData(this.title, this.formData)
      if (this.formData.userId) {
        this.getStuDataByUserId(this.formData.userId)
        this.clearDisabled = false
      }
    })
  },
  methods: {
    addDormRoomBed, // 保存api
    // 远程搜索 教职工名
    async querySearchAsync (keyword, cb) {
      let _result = []
      /* 判断是否请求接口 为false 时请求接口
      * 默认为 true ,因为为false+keyword===''时加载所有的几万条教职工数据
      * */
      let _correctQuery = false
      if (keyword) {
        if (!isNaN(keyword)) {
          // 支持学号、身份证号(number)搜索,但是学号/考号/身份证号必须填入6位数以上,减轻服务器压力
          if (keyword.length > 5) {
            _correctQuery = true
          }
        } else if (keyword.length > 1) {
          // 为string,且关键字大于一个字才能开始搜索,减轻服务器压力
          _correctQuery = true
        }
      }
      if (_correctQuery) {
        const _query = {
          keyword: keyword,
          pageNum: 1,
          userType: 3,
          pageSize: 1000
        }
        try {
          const res = await getUserList(_query)
          res.data.list.map((item) => {
            Object.assign(item, { value: item.realName })
          })
          _result = res.data.list
        } catch (e) {
        } finally {
          cb(_result)
        }
      } else {
        this.formData.userId = null
        Object.keys(this.userInfo).forEach(key => {
          if (key !== 'value') {
            this.userInfo[key] = null
          }
        })
        cb(_result)
      }
    },
    // 获取选中的教职工信息
    handleSelect (teac) {
      this.formData.userId = teac.id
      copyData(teac, this.userInfo)
    },
    // 根据userId获取绑定得教职工信息
    async getStuDataByUserId (id) {
      try {
        const res = await getUserInfoById(id)
        copyData(res.data, this.userInfo)
      } catch (e) {
      }
    },
    // 清空床位
    clearBed () {
      if (!this.formData.userId) {
        this.$message.error('未绑定教职工,无法执行清空操作!')
        return
      }
      this.$confirm('清空该床位, 是否继续?', '提示')
        .then(async () => {
          try {
            this.formData.userId = ''
            await addDormRoomBed(this.formData)
            Object.keys(this.userInfo).forEach(key => {
              this.userInfo[key] = null
            })
            this.$message.success('已清空')
            this.clearDisabled = true
            this.setInitData()
          } catch (e) {
          }
        })
        .catch(() => {
          this.$message('已取消')
        })
    }
  }
}
</script>
<style lang="scss">
.bind-bed-by-teacher-dialog {
  .el-dialog {
    .el-dialog__body {
      padding-top: 0;

      .descriptions-item__label {
        width: 80px;
      }
    }
  }
}
</style>
