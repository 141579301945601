<template>
  <el-dialog
    class="stu-info-dialog"
    title="学生宿舍信息"
    :visible.sync="show"
    width="840px"
    :before-close="closed"
  >
    <el-descriptions :column="3" border>
      <el-descriptions-item  label-class-name="descriptions-item__label" label="录取编号">
        {{ title.examineNo }}
      </el-descriptions-item>
      <el-descriptions-item  label-class-name="descriptions-item__label" label="学号">
        {{ title.stuNo }}
      </el-descriptions-item>
      <el-descriptions-item  label-class-name="descriptions-item__label" label="姓名">
        {{ title.stuName }}
      </el-descriptions-item>
      <el-descriptions-item  label-class-name="descriptions-item__label" label="性别">
        {{ Number(title.sexType) === 1 ? '男' : (Number(title.sexType) === 2 ? '女' : '') }}
      </el-descriptions-item>
      <el-descriptions-item  label-class-name="descriptions-item__label" label="院系">
        {{ title.collegeName }}
      </el-descriptions-item>
      <el-descriptions-item  label-class-name="descriptions-item__label" label="专业">
        {{ title.majorName }}
      </el-descriptions-item>
      <el-descriptions-item  label-class-name="descriptions-item__label" label="班级">
        {{ title.className ? title.className : '未绑定' }}
      </el-descriptions-item>
      <el-descriptions-item  label-class-name="descriptions-item__label" label="宿舍楼">
        {{ title.dormitoryName }}{{ title.floorNum }}层
      </el-descriptions-item>
      <el-descriptions-item  label-class-name="descriptions-item__label" label="宿舍">
        {{ title.roomName }}_{{ title.dormitoryBedNo }}号床
      </el-descriptions-item>
    </el-descriptions>
    <template #footer>
      <el-button @click="closed">关闭</el-button>
    </template>
  </el-dialog>
</template>

<script>
import dialog from '@/vue/mixins/dialog'

export default {
  name: 'stuInfoDialog',
  mixins: [dialog],
  data () {
    return {
    }
  }
}
</script>

<style lang="scss">
.stu-info-dialog{
  .descriptions-item__label {
    width: 100px;
  }
}
</style>
