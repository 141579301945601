<!--教师宿舍-->
<template>
  <div style="width: 100%;">
    <el-table :data="dormBedData" style="width: 100%" border stripe size="mini">
      <el-table-column prop="dormitoryBedNo" label="床位号" width="80">
        <template v-slot="{row}">
          <span>{{ row.dormitoryBedNo }}</span>
        </template>
      </el-table-column>
      <el-table-column label="状态" width="80">
        <template v-slot="{ row }">
          <span v-if="!row.userId">空床位</span>
          <span v-else>已绑定</span>
        </template>
      </el-table-column>
      <el-table-column label="启动状态" width="80">
        <template v-slot="{ row }">
          <el-tag type="success" v-if="Number(row.startUpFlag) === 1">启用</el-tag>
          <el-tag type="danger" v-else>禁用</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="realName" label="教职工姓名" min-width="100" />
      <el-table-column prop="phone" label="教职工电话" min-width="100" />
      <el-table-column prop="sexType" label="性别" width="50">
        <template v-slot="{ row }">
          {{ Number(row.sexType) === 1 ? '男' : (Number(row.sexType) === 2 ? '女' : '') }}
        </template>
      </el-table-column>
      <el-table-column prop="broadbandAccount" width="120" label="宽带账号" />
      <el-table-column width="240" label="操作" fixed="right">
        <template v-slot="{ row }">
            <!--    教职工宿舍   -->
            <!--  请注意showSubDialog方法赋值title时，...row要放currentRoomInfo 后面，否则id属性将是宿舍id,而不是床位id   -->
            <el-button v-permission="['dormitoryBed:bind','secondCollege:bed:bind']"
                       type="primary" size="mini"
                       @click="showSubDialog('visibleBindBedByTeacherDialog','bindBedByTeacherDialogRef',row.id,{...currentRoomInfo,...row})">
              绑定教职工
            </el-button>
            <el-button v-permission="['dormitoryBed:bind','secondCollege:bed:bind']"
                       type="primary" size="mini"
                       @click="editBroadbandAccount(row)">
              宽带账号
            </el-button>
            <el-popover
              placement="left"
              width="120"
              title="更多操作"
              trigger="hover">
              <el-button type="danger" size="mini" plain
                         v-permission="['dormitoryBed:clear','secondCollege:stu:clear']"
                         @click="$emit('on-clear',row)">清空
              </el-button>
              <el-button type="danger" size="mini" v-permission="['dormitoryBed:del','secondCollege:stu:del']"
                         @click="del(deleteDormRoomBed,row.id, `${currentRoomInfo.floorNum }楼${currentRoomInfo.label}房${row.dormitoryBedNo}号床位`)">
                删除
              </el-button>
              <el-button slot="reference"
                         v-permission="['dormitoryBed:clear','secondCollege:stu:clear','dormitoryBed:del','secondCollege:stu:del']"
                         icon="el-icon-more" />
            </el-popover>
        </template>
      </el-table-column>
    </el-table>
    <!--  添加/编辑床位 弹窗  -->
    <addRoomBed ref="dialogRef" :dorm-info="currentRoomInfo" :visible.sync="visibleDialog" v-if="visibleDialog"
                @on-close="$emit('on-reload')" />
    <!-- 绑定教师宿舍床位   -->
    <bind-bed-by-teacher-dialog ref="bindBedByTeacherDialogRef"
                                :visible.sync="visibleBindBedByTeacherDialog"
                                v-if="visibleBindBedByTeacherDialog"
                                @on-close="$emit('on-reload')" />
  </div>
</template>

<script>
import BindBedByTeacherDialog from '@/views/pages/dormManage/dorm-room-bed/component/bindBedByTeacherDialog.vue'
import tableView from '@/vue/mixins/table-view'
import { deleteDormRoomBed, addDormRoomBed } from '@/api/dorm'
import addRoomBed from '@/views/pages/dormManage/dialog/addDormRoomBed.vue'

export default {
  name: 'dormTeacherView',
  mixins: [tableView],
  components: {
    BindBedByTeacherDialog,
    addRoomBed
  },
  props: {
    dormBedData: {
      type: Array,
      default: () => ([])
    },
    currentRoomInfo: { // 当前显示的宿舍信息
      type: Object,
      default: () => ({
        floorNum: '',
        label: ''
      })
    }
  },
  data() {
    return {
      visibleBindBedByTeacherDialog: false // 绑定教师宿舍床位
    }
  },
  methods: {
    deleteDormRoomBed, // 删除api
    renderTable() {
      this.$emit('on-reload')
    },
    // 修改宽带账号
    editBroadbandAccount(row) {
      this.$prompt(`请输入宽带账号(<b>原宽带账号：${row.broadbandAccount ? row.broadbandAccount : '未设置'}</b>)`, '设置门锁码', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /^.+$/,
        inputValue: row.lockFeatureCode || '',
        dangerouslyUseHTMLString: true
      }).then(({ value }) => {
        this.$confirm('是否确认修改宽带账号？').then(async () => {
          try {
            await addDormRoomBed({
              id: row.id,
              broadbandAccount: value
            })
            this.$message.success(`${row.roomName}宽带账号修改成功!`)
            await this.renderTable()
          } catch (e) {
            if (!e) {
              this.$message.error('数据获取失败，请重试！')
            }
          }
        })
      }).catch(() => {
      })
    }
  }
}
</script>

<style scoped>

</style>
