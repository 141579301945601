<template>
  <el-dialog
    class="check-in-photo-dialog"
    title="绑定教职工宿舍床位"
    :visible.sync="show"
    :close-on-click-modal="false"
    width="1060px"
    :before-close="closed"
  >
    <check-in-photo v-if="showCamera" ref="checkInPhotoRef" :visible-search-bar="false" />
    <template #footer>
      <el-button @click="closed">关 闭</el-button>
    </template>
  </el-dialog>
</template>

<script>
import dialog from '@/vue/mixins/dialog'
import CheckInPhoto from '@/views/pages/recruitManage/check-in-photo/checkInPhoto.vue'
import { getStudentById } from '@/api/student'

export default {
  name: 'CheckInPhotoDialog',
  components: { CheckInPhoto },
  mixins: [dialog],
  data() {
    return {
      showCamera: false
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.showCamera = true
      this.$nextTick(() => {
        this.getUserInfo()
      })
    })
  },
  methods: {
    async getUserInfo() {
      // 无法获取学生id 因此暂时作废
      try {
        this.$refs.checkInPhotoRef.loading = true
        const res = await getStudentById(this.title.userId)
        this.$refs.checkInPhotoRef.userInfo.data = res.data
      } catch (e) {
        console.error(e)
      } finally {
        this.$refs.checkInPhotoRef.loading = false
      }
    }
  }
}
</script>
<style lang="scss">
.check-in-photo-dialog {
  .check-in-photo {
    .el-card {
      box-shadow: none;
      margin: 0;
    }
  }
}
</style>
