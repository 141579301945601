<template>
  <el-dialog
    class="addDormRoomBed-dialog"
    :title="`${preTitle}床位`"
    :visible.sync="show"
    :close-on-click-modal="false"
    width="540px"
    :before-close="cancel"
  >
    <el-form
      ref="form"
      :model="formData"
      :rules="rules"
      label-width="100px"
    >
      <el-form-item label="所在楼栋">
        <el-select disabled v-model="dormInfo.dormitoryInfoId">
          <el-option
            v-for="{dormitoryName, id} in dormBuildList"
            :label="dormitoryName"
            :value="id"
            :key="id" />
        </el-select>
      </el-form-item>
      <el-form-item label="所在楼层">
        <el-input disabled :value="`第${dormInfo.floorNum}层`" />
      </el-form-item>
      <el-form-item label="宿舍号">
        <el-input disabled v-model.trim="dormInfo.label" />
      </el-form-item>
      <el-form-item label="床铺号" prop="dormitoryBedNo">
        <el-select :disabled="!!id" v-model="formData.dormitoryBedNo">
          <el-option
            v-for="num in peopleAmount"
            :label="num"
            :value="num"
            :key="num" />
        </el-select>
      </el-form-item>
      <el-form-item label="宽带账号" prop="broadbandAccount">
        <el-input v-model="formData.broadbandAccount"/>
      </el-form-item>
      <el-form-item label="宽带初始密码" prop="broadbandPassword">
        <el-input v-model="formData.broadbandPassword"/>
      </el-form-item>
      <el-form-item label="是否启用">
        <el-radio-group v-model="formData.startUpFlag">
          <el-radio :label="1">是</el-radio>
          <el-radio :label="0">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="备注" prop="remarks">
        <el-input v-model.trim="formData.remarks" />
      </el-form-item>
    </el-form>
    <template #footer>
      <cancel-popover ref="cancelRef" :disabled.sync="loadData.loading" :update-flag="updateFlag" />
      <el-button type="primary" @click="save('床位',addDormRoomBed)">确 定</el-button>
    </template>
  </el-dialog>
</template>

<script>
import { addDormRoomBed, getDormRoomBedById, getDormRoomTypeById } from '@/api/dorm'
import dialog from '@/vue/mixins/dialog'

export default {
  mixins: [dialog],
  props: {
    dormInfo: {
      type: Object,
      default: () => ({
        floorNum: 0,
        bedNum: 0,
        dormitoryInfoId: '',
        label: ''
      })
    }
  },
  data () {
    return {
      formData: {
        id: null,
        broadbandAccount: null, // 宽带
        broadbandPassword: null, // 宽带初始密码
        dormitoryBedNo: null, // 床位号
        dormitoryRoomId: '', // 宿舍号
        userId: '', // 宿舍号
        startUpFlag: 1, // 是否启用 1 是 0 否
        remarks: ''
      },
      peopleAmount: 0, // 可入住人数
      dormBuildList: [],
      rules: {
        dormitoryBedNo: [
          {
            required: true,
            message: '请输入床号',
            trigger: 'blur'
          },
          this.$store.state.commonValidate.validatorNumberObj
        ]
      }
    }
  },
  mounted () {
    this.getRoomBuildList()
    this.$nextTick(() => {
      if (this.id) {
        this.getDataById(this.id, getDormRoomBedById)
      } else {
        this.formData.dormitoryRoomId = this.dormInfo.dormitoryRoomId
        this.getDormRoomTypeById(this.dormInfo.roomTypeId)
        this.setInitData()
      }
    })
  },
  methods: {
    addDormRoomBed, // 保存 api
    // 获取宿舍楼栋
    getRoomBuildList () {
      this.$http.getDormBuildQuery({}).then((res) => {
        this.dormBuildList = res.data
      })
    },
    // 根据id获取房间房间类型
    getDormRoomTypeById (dormitoryRoomId) {
      getDormRoomTypeById(dormitoryRoomId).then((res) => {
        this.peopleAmount = res.data.peopleAmount
      })
    }
  }
}
</script>
